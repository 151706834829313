.text-button>p {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.15em;
    color: #6F6F6F;
    cursor: pointer;
}

.text-button>p:hover {
    color: #1C82AD;
}