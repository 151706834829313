.flat-button {
    background: #FFFFFF;
    border: 1px solid #1781BC;
    padding: 1em;
    width: 100%;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    letter-spacing: 0.6em;
    text-indent: 0.6em;
    color: #1781BC;
    align-items: center;
    cursor: pointer;
}

.flat-button.compact {
    padding: 0.25em 0.5em;
}

.flat-button.fit {
    width: fit-content;
    padding: 0.25em 2em;
}

.flat-button.mini {
    padding: 0em 0.5em;
    font-size: 14px;
    width: fit-content;
    min-width: fit-content;
}

.flat-button.curve {
    border-radius: 64px;
}

.flat-button.mini.curve {
    border-radius: 6px !important;
}

.flat-button.active {
    border: 1px solid #74AFD0;
    background: #1781BC;
    color: white;
}

.flat-button:hover {
    background-color: #1781BC20;
}

.flat-button.active:hover {
    background-color: #1781BC;
    border: 1px solid #1781BC;
}

.flat-button-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.flat-button-container.fit {
    width: fit-content;
}