.dialog-box-zone {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    background-color: #000000D0;
    align-items: center;
    z-index: 9999;
}

.dialog-box-parent {
    background: white;
    box-shadow: 0px -3px 45px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.dialog-box-parent.Success {
    border: 3px solid #1780BC;
}

.dialog-box-parent.Error {
    border: 3px solid #F05353;
}

.dialog-box-parent.Missing {
    border: 3px solid #ED7171;
}

.dialog-box-parent.Delete {
    border: 3px solid #F5D147;
}

.dialog-box-parent.Publish {
    border: 3px solid #56A920;
}

.dialog-box-header {
    padding-top: 8px;
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 54px;
}

.dialog-box-header.Success {
    background: #1780BC;
}

.dialog-box-header.Error {
    background: #F05353;
}

.dialog-box-header.Missing {
    background: #ED7171;
}

.dialog-box-header.Delete {
    background: #F5D147;
}

.dialog-box-header.Publish {
    background: #56A920;
}

.dialog-box-icon {
    height: 36px;
}

.dialog-box-label {
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;
    text-align: right;
    color: rgba(255, 255, 255, 0.2);
    margin-left: 64px;
}

.dialog-box-content {
    padding: 2em;
    min-height: 128px;
    min-width: 350px;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.35em;
    color: #333333;
    flex-direction: column;
    gap: 1.5em;
}

.dialog-box-title-parent {
    position: absolute;
    top: 0;
    left: 42px;
    height: 100%;
    display: flex;
    align-items: center;
}

.dialog-box-title {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    width: 256px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dialog-box-checkbox-message {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16em;
    color: #333333;
}

.dialog-box-buttons-parent {
    display: flex;
    gap: 1.5em;
}

.dialog-box-button {
    letter-spacing: 0.1em;
    border-radius: 32px;
    cursor: pointer;
    padding: 0.5em 1em;
}

.dialog-box-button.primary.Success {
    background: #1780BC;
    border: 2px solid #57ACDC;
    color: white;
}

.dialog-box-button.primary.Success:hover {
    border: 2px solid #57ACDC80;
}

.dialog-box-button.secondary.Success {
    background: #DEEEF8;
    border: 2px solid #8CA2C4;
    color: #4A7AA7;
}

.dialog-box-button.secondary.Success:hover {
    border: 2px solid #8CA2C480;
}

.dialog-box-button.primary.Error {
    background: #EE4F4F;
    border: 2px solid #F19696;
    color: white;
}

.dialog-box-button.primary.Error:hover {
    border: 2px solid #F1969680;
}

.dialog-box-button.secondary.Error {
    background: #CD7171;
    border: 2px solid #FFCCCC;
    color: #AF6565;
}

.dialog-box-button.secondary.Error:hover {
    border: 2px solid #FFCCCC80;
}

.dialog-box-button.primary.Missing {
    background: #ED6060;
    border: 2px solid #F19696;
    color: white;
}

.dialog-box-button.primary.Missing:hover {
    border: 2px solid #F1969680;
}

.dialog-box-button.secondary.Missing {
    background: #F8DEDE;
    border: 2px solid #C48C8C;
    color: #AF6565;
}

.dialog-box-button.secondary.Missing:hover {
    border: 2px solid #C48C8C80;
}

.dialog-box-button.primary.Delete {
    background: #BF8719;
    border: 2px solid #DCBF57;
    color: white;
}

.dialog-box-button.primary.Delete:hover {
    border: 2px solid #DCBF5780;
}

.dialog-box-button.secondary.Delete {
    background: #F2DFC8;
    border: 2px solid #CC9D67;
    color: #A7874A;
}

.dialog-box-button.secondary.Delete:hover {
    border: 2px solid #CC9D6780;
}


.dialog-box-button.primary.Publish {
    background: #1A8725;
    border: 2px solid #93D49B;
    color: white;
}

.dialog-box-button.primary.Publish:hover {
    border: 2px solid #93D49B80;
}

.dialog-box-button.secondary.Publish {
    background: #D7E8B3;
    border: 2px solid #93CC67;
    color: #62A74A;
}

.dialog-box-button.secondary.Publish:hover {
    border: 2px solid #93CC6780;
}

.dialog-box-list-info-container {
    max-height: 196px;
    background: #00000020;
    width: 100%;
    padding: 1em;
    border-radius: 0.5em;
    overflow: scroll;
    font-weight: 500;
    line-height: 1.5em;
    font-size: 14px;
    text-indent: 0em;
    letter-spacing: 0.05em;
}

.dialog-box-list-info-index {
    width: 24px;
    opacity: 0.5;
}