.button-parent {
    padding: 8px;
    color: white;
    font-weight: 700;
    font-size: 17px;
    border-radius: 4em;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em;
}