.text-tab-container{
    display: flex;
}

.text-tab{
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.15em;
    text-indent: 0.15em;
    color: #9E9E9E;
    cursor: pointer;
    padding: 0em 1em;
    border-right: 1px solid #9E9E9E;
}

.text-tab:hover{
    font-weight: 700;
}

.text-tab.active{
    color: #1C82AD;
    font-weight: 700;
}