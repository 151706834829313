.sidebar {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: hidden scroll;
    min-width: 180px;
    border-right: 0.5px solid #9E9E9E80;
    background: #F2F2F2;
    height: calc(100vh - 75px);
    width: 196px;
}

.sidebar-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5em;
    text-align: center;
    color: #1976d2;
}

.sidebar-children {
    display: flex;
    flex-direction: column;
    background: #FAFAFA;
}

.sidebar-buttons {
    background: #F2F2F2;
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.2em;
    color: #333333;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 1em;
    border-bottom: 0.5px solid rgba(158, 158, 158, 0.5019607843);
    cursor: pointer;
}