@import "../../master.scss";
@import "../variables.scss";

.top-bar {
    background: #1781BC;
    padding: 0.5em 1.5em;
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $topbarHeight;
    z-index: 8;
}

.main-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    font-family: "Noto Sans TC";
    padding-left: 1em;
}


.top-bar-right {
    display: flex;
    gap: 2em;
    justify-content: center;
    align-items: center;
}

.nav-title {
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2em;
    color: #FFFFFF;
    cursor: pointer;
}

.profile-picture {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;
    background: transparent;
}

.bottom-content {
    padding-top: 64px;
    display: flex;
}

.main-content {
    width: -webkit-fill-available;
    background-color: white;
    overflow: scroll;
    height: calc(100vh - $topbarHeight);
    overflow-x: hidden;
}