@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&display=swap');

@font-face {
    font-family: "Helvetica Neue";
    src: url("./assets/fonts/helveticaneue_medium-webfont.woff") format("woff2"),
        url("./assets/fonts/helveticaneue_medium-webfont.woff2") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'edukai';
    src: url('./assets/fonts/edukai.woff2') format('woff2'),
        url('./assets/fonts/edukai.woff') format('woff'),
        url('./assets/fonts/edukai.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    unicode-range: U+2E00-FFFF;
}

@font-face {
    font-family: 'edusong';
    src: url('./assets/fonts/edusong.woff2') format('woff2'),
        url('./assets/fonts/edusong.woff') format('woff'),
        url('./assets/fonts/edusong.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    unicode-range: U+2E00-FFFF;
}

.h {
    display: flex;
}

.hide {
    display: none !important;
}

.rel {
    position: relative;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.height-100vh {
    height: 100vh;
    overflow: hidden;
}

.right-border {
    border-right: 1px solid #1976d240;
}

.full-width {
    width: 100%;
}

.gap-v-s {
    gap: 0.5em;
}

.gap-v-m {
    gap: 1em;
    display: flex;
    flex-direction: column;
}

.gap-v-l {
    gap: 2em;
}

.a-clear {
    text-decoration: unset;
    color: unset;
}

.question-block {
    background-color: white;
    padding: 0 1em 1em 1em;
}

.question-block-first {
    align-self: flex-end;
}

.text-field {
    width: 100%;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    // line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    // display: -webkit-inline-box;
    // display: -webkit-inline-flex;
    // display: -ms-inline-flexbox;
    // display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 4px 0 5px;
    width: 100%;
    position: relative;
    background-color: rgba(0, 0, 0, 0.06);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    padding: 12px;
    white-space: nowrap;
    box-sizing: border-box;
}

.editor-wrapper {
    width: 100%;
}

.public-DraftEditorPlaceholder-inner {
    white-space: nowrap !important;
}

.divider-h {
    height: 2px;
    width: 100%;
    background-color: #1976d2;
}

input.apple-switch {
    position: relative;
    appearance: none;
    outline: none;
    width: 50px;
    height: 24px;
    background-color: #ffffff;
    border: 2px solid #D9DADC;
    border-radius: 24px;
    box-shadow: inset -10px 0 0 0 #ffffff;
    transition-duration: 250ms;
}

input.apple-switch:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: #A0A0A0;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

input.apple-switch:checked {
    border-color: #4ED164;
    background-color: transparent;
    box-shadow: inset 20px 0 0 0 #4ED164;
}

input.apple-switch:checked:after {
    width: 16px;
    height: 16px;
    right: 2px;
    top: 2px;
    box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}

input[role=switch] {
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    display: inline-block;
    width: 2.6em;
    height: 1.4em;
    margin: -.1em 0;
    box-sizing: content-box;
    padding: 0;
    border: 1px solid #AFAFAF;
    border-radius: 1em;
    background: white;
    transition: background-color 250ms ease, box-shadow 250ms ease;
    font-size: 100%;
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    user-select: none;
    outline: none;
}

input[role=switch]::before {
    content: '';
    display: flex;
    align-content: center;
    justify-content: center;
    position: absolute;
    width: 1em;
    height: 1em;
    left: 0;
    top: 0;
    background: #AFAFAF;
    border-radius: 50%;
    transform: translate(20%, 20%);
    transition: transform 250ms ease;
    color: rgba(0, 0, 0, 0.3);
    line-height: 1;
}

input[role=switch]:checked {
    border: 1px solid #4ED164;
}

input[role=switch]:focus-visible {
    box-shadow: 0 .15em .25em rgba(0, 0, 0, 0.5) inset, 0 -.5px 0 rgba(255, 255, 255, 0.2) inset, 0 0 0 2px rgba(255, 255, 255, 0.8), 0 0 0 4px var(--bg-checked, var(--bg, rgb(60, 130, 250)));
}

input[role=switch]:checked::before {
    background: #4ED164;
    transform: translate(140%, 20%);
}

input[role=switch]:indeterminate::before {
    transform: translate(70%, 20%);
    content: '-';
}

input[role=switch]:disabled:before {
    opacity: 0.4;
}

.rdw-editor-toolbar {
    border: unset !important;
    padding: unset !important;
    background: transparent !important;
}

.public-DraftStyleDefault-block {
    margin: 0px !important;
}

.checkbox-wrapper-40 {
    --borderColor: #D9D9D9;
    --borderWidth: .125em;
}

.checkbox-wrapper-40 label {
    display: block;
    max-width: 100%;
    margin: 0 auto;
}

.checkbox-wrapper-40 input[type=checkbox] {
    -webkit-appearance: none;
    appearance: none;
    vertical-align: middle;
    background: #fff;
    font-size: 1.8em;
    border-radius: 5px;
    display: inline-block;
    border: var(--borderWidth) solid #D9D9D9;
    width: 1em;
    height: 1em;
    position: relative;
}

.checkbox-wrapper-40 input[type=checkbox]:before,
.checkbox-wrapper-40 input[type=checkbox]:after {
    content: "";
    position: absolute;
    background: #3AB437;
    width: calc(var(--borderWidth) * 3);
    height: var(--borderWidth);
    top: 50%;
    left: 10%;
    transform-origin: left center;
}

.checkbox-wrapper-40 input[type=checkbox]:before {
    transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(0);
    transition: transform 65ms ease-in 65ms;
}

.checkbox-wrapper-40 input[type=checkbox]:after {
    width: calc(var(--borderWidth) * 5);
    transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(0);
    transform-origin: left center;
    transition: transform 65ms ease-in;
}

.checkbox-wrapper-40 input[type=checkbox]:checked:before {
    transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(1);
    transition: transform 65ms ease-in;
}

.checkbox-wrapper-40 input[type=checkbox]:checked:after {
    width: calc(var(--borderWidth) * 5);
    transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(1);
    transition: transform 65ms ease-out 65ms;
}

.shake {
    animation: shake 0.5s;
    animation-iteration-count: 1;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.s-g {
    gap: 0.5em;
}

.m-g {
    gap: 1em;
}

.l-g {
    gap: 2em;
}

.grey-backdrop {
    background: #F4F4F4;
}

.edukai {
    font-family: 'edukai';
}

.edusong {
    font-family: 'edusong';
}

.version {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    bottom: 0.5em;
    align-items: flex-end;
    justify-content: flex-end;
    opacity: 0.25;
    pointer-events: none;
    right: 0.5em
}

.fixed-full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.dark-backdrop {
    background: #000000B0;
}

.self-end {
    align-self: flex-end;
}

.button {
    cursor: pointer;
}

.p-s {
    padding: 0.5em;
}

.p-m {
    padding: 1em;
}

.align-left {
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
}

.bottom-border {
    border-bottom: 1px solid #9E9E9E80;
}

.flex-v {
    display: flex;
    flex-direction: column;
}

.p-v-s {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.p-v-m {
    padding-top: 1em;
    padding-bottom: 1em;
}

.w-100 {
    width: 100%;
}

.fill-remaining {

    width: -webkit-fill-available;
}

.top-left-right-p-m {
    padding: 1em 1em 0em 1em;
}

.left-right-p-m {
    padding: 0em 1em 0em 1em;
}