.question-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
}

.question-answer-label {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.6em;
    color: #1781BC;
}

.question-category-options {
    padding-top: 16px;
    padding-bottom: 16px;
    gap: 1em;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.question-title-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 56px;
}

.question-title {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.6em;
    text-indent: 0.6em;
    color: #1781BC;
}