.master-question-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
    position: relative;
}

.master-question-container.subgroup {
    background: #FAFAFA;
}

.master-question-publish-group {
    display: flex;
    gap: 0.5em;
    align-items: center;
}

.master-question-publish-text {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.3em;
    text-indent: 0.3em;
    color: #1781BC;
}

.master-question-title-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 56px;
}

.master-question-title {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.6em;
    text-indent: 0.6em;
    color: #1781BC;
}

.master-question-add-question-button {
    background: #69A3C5;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.5em 0.75em;
    letter-spacing: 0.6em;
    text-indent: 0.6em;
    color: #FFFFFF;
    cursor: pointer;
}

.master-question-category-options {
    gap: 1em;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.drafjs-custom-icon {
    border: unset;
    padding: 5px;
    min-width: 25px;
    height: 32px;
    border-radius: 2px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent;
    text-transform: capitalize;
    box-shadow: unset;
    width: fit-content;
}

.drafjs-custom-icon:hover {
    border: unset;
    box-shadow: unset !important;
}

.rdw-option-wrapper {
    height: unset;
    border: unset !important;
    min-width: unset;
}