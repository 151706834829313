h2{
    text-align: center;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container{
    background: linear-gradient(-135deg,#c850c0,#4158d0);
    width: 100%;
    min-height: 100vh;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.wrap-login{
    width: 960px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 36px;
    gap: 16px;
    box-sizing: border-box;
}

.login-image{
    width: 35%;
    max-width: 316px;
    will-change: transform;
    transform: perspective(300px) rotateX(0deg) rotateY(0deg);
}

.login-form{
    width: calc(65% - 16px);
    max-width: 512px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2em;
}

@media (max-width: 768px){
    .login-image{
        width: 0%;
        display: none;
    }

    .login-form{
        width: 100%;
    }

    .wrap-login{
        justify-content: center;
    }
}

#tilt {
    box-shadow: 0 0px 0px rgba(0,0,0,0) !important;
  }