.sidebar-option {
    background: #FAFAFA;
    height: 40px;
    font-family: "Noto Sans TC";
    border-bottom: 0.5px solid rgba(158, 158, 158, 0.5019607843);
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #333333;
    cursor: pointer;
    padding-left: 1.5em;
    width: 100%;
}

.sidebar-option:hover{
    font-weight: 700;
}

.sidebar-option:active{
    color: #33333380;
}

.sidebar-option-text{
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar-option.active{
    background: #cfcfcf;
}