.publish-status-backdrop {
    background: white;
    // padding: 1em;
    font-family: 'NOTO SANS TC';
}

.publish-status-question {
    font-family: 'edusong';
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3.6em;
    line-height: 1.8em;
}

.publish-status-context {
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    background: #EAF6E2;
    border-radius: 4px;
    padding: 1em;
    margin-bottom: 1em;
}

.publish-status-index {
    display: block;
    max-height: 3.6em;
    line-height: 1.8em;
    text-align: center;
    padding: 1em;
}

.publish-status-subindex {
    display: block;
    max-height: 3.6em;
    line-height: 1.8em;
    font-size: 14px;
    font-style: italic;
    opacity: 0.5;
    text-align: center;
}

.publish-question-question-container {
    display: grid;
    grid-template-columns: 24px 36px 1fr 96px;
    gap: 1em;
    align-items: flex-start;
}

.no-overflow {
    overflow: hidden;
}

.publish-status-publish-status {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
}

.publish-status-publish-status.true {
    color: #56A920;
}

.publish-status-publish-status.false {
    color: #BE9E4A;
}

.highlighted {
    background: #EFF7FB;
}