.sortation-question-header-text {
    width: 100%;

    * {
        font-family: "edusong" !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-height: 3.6em;
        line-height: 1.8em;
        width: 100%;
    }
}

.sortation-question-header-content {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    padding: 1em;
    background: white;
    cursor: grab;
}

.sortation-question-header-index {
    font-size: 12px;
    opacity: 0.5;
}