@import "../../master.scss";

.parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2em;
    height: 100vh;
    background: white;
}

.creator-parent {
    background: #E8F6FB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em 6em 2em 6em;
    gap: 4em;
    border-radius: 5px;
    margin-bottom: 2em;
}

.logo {
    height: 150px;
    width: 150px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #F4F8FA;
    background-image: url("../../assets/images/dropdown_arrow.svg");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 0.2em;
    border: 1px solid #1781BC;
    border-radius: 4em;
    margin-left: 1em;
    padding: 0.4em;
    padding-right: 4em;
    padding-left: 1em;
    color: #226074;
    font-size: 14px;
    font-family: "Noto Sans TC";
    font-weight: 700;
    cursor: pointer;
    width: 128px;
}

.dropdown-parent {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.paper-title-input {
    background: #F4F8FA;
    border-radius: 4em;
    margin-left: 1em;
    border: 1px solid #1781BC;
    color: #226074;
    font-family: "Noto Sans TC";
    padding: 0.3em;
    font-weight: 700;
    padding-left: 1em;
    width: 128px;
}

label {
    font-weight: 400;
    font-family: "Noto Sans TC";
    font-size: 14px;
}

.title {
    font-weight: 700;
    font-size: 24px;
    color: #226074;
    padding: 1em;
}

.creator-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4em
}

.creator-button {
    padding: 0.6em 2em;
    color: white;
    font-weight: 700;
    font-size: 17px;
    background:  #1781BC;
    border: 2px solid #1781BC;
    border-radius: 4em;
    letter-spacing: 0.6em;
    cursor: pointer;
    text-indent: 0.6em;
}

.button-disabled {
    cursor: not-allowed;
    filter: grayscale(100);
}

.creator-button:hover{
    border: 2px solid #9CB5CC;
}

.dropdown{
     margin-bottom:1em;
}

