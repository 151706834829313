.question-preview-backdrop {
    background: white;
    padding: 1.5em;
    display: grid;
    grid-template-columns: 1fr 53px;
}

.question-preview-context {
    background: #E8F6FB;
    border-radius: 5px;
    padding: 1em 2em;
    max-height: 300px;
    overflow-y: scroll;
}

.question-preview-question {
    font-family: 'edusong';
    font-size: 16px;
}

.question-preview-description {
    font-family: 'edukai';
    opacity: 0.75;
    line-height: 18px;
    letter-spacing: 0.175em;
    color: #333333;
    font-size: 16px;
}

.question-preview-context>p {
    font-family: 'edukai';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 135%;
    letter-spacing: 0.15em;
    white-space: pre-wrap;
}

.question-preview-context>.quote {
    text-align: end;
    // italic
    font-style: italic;
    opacity: 0.5;
    font-size: 1.1em;
}

.question-preview-question-container {
    display: flex;
}

.question-preview-question-container>p {
    font-family: 'edusong';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #333333;
}

.question-preview-question-index {
    width: 32px;
}

.question-preview-answer-container {
    display: flex;
    align-items: center;
    gap: 1em;
}

.question-preview-answer-index {
    display: flex;
    font-family: 'edusong';
    background: #F1F3F0;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
}

.question-preview-answer-index.active {
    background: #1C82AD;
    color: white;
}

.question-preview-explanation-container {
    background: #FDFBF1;
    border-radius: 2px;
}

.question-preview-explanation-title {
    padding: 1em 1.5em;
    display: flex;
    align-items: center;
    letter-spacing: 0.175em;
    border-bottom: 0.8px dashed #BFBFBF;
    justify-content: space-between;
}

.question-preview-explanation-title>p {
    font-family: 'edukai';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #8A5E37;
}

.question-preview-explanation-type {
    font-family: 'Noto Sans TC' !important;
    background: #1781BC;
    border: 1px solid #74AFD0;
    border-radius: 6px;
    padding: 0.5em 1em;
    font-size: 14px !important;
    color: white !important;
}

.question-preview-explanation-type.tone-down {
    opacity: 0.5;
}

.question-preview-source {
    font-family: "Helvetica Neue";
    white-space: pre-wrap;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 134.5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    letter-spacing: 0.18em;
    color: #3D7FB7;
    padding-right: 1em;
    padding-bottom: 1em;
}

.question-preview-explanation-answer {
    padding: 1em 1.5em 0em 1.5em;
    font-family: 'edukai';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 134.5%;
    letter-spacing: 0.175em;
    color: #333333;
}

.question-preview-explanation-details {
    padding: 0.5em 1.5em 1.5em 1.5em;
    font-family: 'edukai';
    white-space: pre-wrap;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 134.5%;
    letter-spacing: 0.175em;
    color: #333333;
}

.question-preview-context-image-container {
    max-width: 100%;
    max-height: 320px;
}

.question-preview-context-image {
    max-width: 100%;
    max-height: 320px;
    width: auto;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}

.question-preview-edit-button {
    margin-left: auto;
    align-self: flex-start;
    padding: 1em;
    cursor: pointer;
}

.question-preview-edit-container {
    width: 36px;
    position: relative;
}

.question-preview-edit-backdrop {
    background: white;
    border: 1px solid #E3E3E3;
    box-shadow: 0px 4px 3px rgb(211 210 203 / 30%);
    border-radius: 4px;
    position: absolute;
    min-width: 140px;
    right: 0;
}

.question-preview-edit-buttons-layout {
    display: flex;
    justify-content: center;
    margin: 0.5em;
    gap: 1em;
    padding: 0.2em;
    color: #446493;
    background: white;
    border-radius: 2px;
    cursor: pointer;
}

.question-preview-edit-buttons-layout>p {
    font-weight: 700;
}

.question-preview-edit-buttons-layout:hover {
    background: #1780BC;
}

.question-preview-edit-buttons-layout:hover>p {
    color: white;
}

.question-preview-edit-buttons-layout:hover>img {
    filter: brightness(10);
}

.question-preview-edit-buttons-text {
    font-family: "Noto Sans TC";
    letter-spacing: 0.15em;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    white-space: nowrap;
}

.question-preview-accuracy-wrapper .tag {
    background: white;
    border-radius: 32px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(2px);
}

.question-preview-accuracy-wrapper .tag.green {
    border: 2px solid #A9DDB0;
}

.question-preview-accuracy-wrapper .tag.blue {
    border: 2px solid #E1F1F0;
}

.question-preview-accuracy-wrapper .tag.purple {
    border: 2px solid #F0EBF4;
}

.question-preview-accuracy-wrapper .tag.yellow {
    border: 2px solid #F5F4F0;
}

.question-preview-accuracy-wrapper .tag.active {
    color: white;
}

.question-preview-accuracy-wrapper .tag.green.active {
    border: 2px solid #297E60;
    background: #49A886;
}

.question-preview-accuracy-wrapper .tag.blue.active {
    background: #4e82d0;
    border: 2px solid #3C639D;
}

.question-preview-accuracy-wrapper .tag.purple.active {
    background: #A789CD;
    border: 2px solid #4c3071;
}

.question-preview-accuracy-wrapper .tag.yellow.active {
    background: #ddb822;
    border: 2px solid #a48f31;
}

.question-preview-accuracy-wrapper .answer-rate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
}

.question-preview-accuracy-wrapper .answer-rate .rate-bar {
    width: 320px;
    max-width: 30vw;
    background: #F2F1E8;
    height: 12px;
}

.question-preview-accuracy-wrapper .answer-rate .rate-bar .rate-bar-left {
    height: 100%;
}

.question-preview-accuracy-wrapper .answer-rate .rate-bar .rate-bar-left.green {
    background: #A9DDB0;
}

.question-preview-accuracy-wrapper .answer-rate .rate-bar .rate-bar-left.blue {
    background: #4e82d0;
}

.question-preview-accuracy-wrapper .answer-rate .rate-bar .rate-bar-left.purple {
    background: #A789CD;
}

.question-preview-accuracy-wrapper .answer-rate .rate-bar .rate-bar-left.yellow {
    background: #cfb960;
}

.question-preview-accuracy-wrapper .answer-rate .rate-bar .rate-bar-left.green.active {
    background: #297E60;
}

.question-preview-accuracy-wrapper .answer-rate .rate-bar .rate-bar-left.blue.active {
    background: #3C639D;
}

.question-preview-accuracy-wrapper .answer-rate .rate-bar .rate-bar-left.purple.active {
    background: #4c3071;
}

.question-preview-accuracy-wrapper .answer-rate .rate-bar .rate-bar-left.yellow.active {
    background: #a48f31;
}

.question-preview-accuracy-wrapper .answer-rate .rate-label {
    padding-left: 16px;
    font-family: Noto Sans TC;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: left;
}

.question-preview-accuracy-wrapper .answer-rate .rate-label.green {
    color: #5C9965;
}

.question-preview-accuracy-wrapper .answer-rate .rate-label.blue {
    color: #4e82d0;
}

.question-preview-accuracy-wrapper .answer-rate .rate-label.purple {
    color: #A789CD;
}

.question-preview-accuracy-wrapper .answer-rate .rate-label.yellow {
    color: #cfb960;
}

.question-preview-accuracy-wrapper .answer-rate .rate-label.green.active {
    color: #297E60;
}

.question-preview-accuracy-wrapper .answer-rate .rate-label.blue.active {
    color: #3C639D;
}

.question-preview-accuracy-wrapper .answer-rate .rate-label.purple.active {
    color: #4c3071;
}

.question-preview-accuracy-wrapper .answer-rate .rate-label.yellow.active {
    color: #a48f31;
}

.question-preview-accuracy-wrapper .answer-rate .rate-label::after {
    content: '%';
    font-family: Noto Sans TC;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: left;
}

.question-preview-accuracy {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
}

.question-preview-accuracy-summary-wrapper {
    background: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    gap: 16px;

    font-family: Noto Sans TC;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.1em;
    text-align: left;
}

.question-preview-accuracy-summary-wrapper .bold {
    font-weight: 700;
    font-size: 20px;
}

.question-with-answer-category-tag {
    background: #1781BC;
    border: 2px solid #74AFD0;
    box-sizing: border-box;
    border-radius: 6px;
    color: white;
    font-family: "Noto Sans TC";
    padding: 0.25em 1em;
}