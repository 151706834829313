.drag-and-drop-uploaded-image-container{
    border: 1px solid #1781BC;
    padding: 1em;
}

.drag-and-drop-uploaded-image-container > img{
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-height: 256px;
}