.statistic-content-wrapper {
    margin-top: 64px;
    padding: 0 16px;
    display: flex;
    justify-content: center;
}

.statistic-content {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.statistic-tabs-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 32px;
    gap: 16px;
}

.statistics-tab {
    font-family: "Noto Sans TC";
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.15em;
    text-align: center;
    color: #9E9E9E;
    cursor: pointer;
}

.statistics-tab.green.on {
    color: #0C7510;
}

.statistics-tab.blue.on {
    color: #1F5194;
}

.statistics-tab.purple.on {
    color: #5D4890;
}

.statistics-tab.yellow.on {
    color: #CC9D67;
}

.statistics-tab.green:hover {
    color: #0C7510;
}

.statistics-tab.blue:hover {
    color: #1F5194;
}

.statistics-tab.purple:hover {
    color: #5D4890;
}

.statistics-tab.yellow:hover {
    color: #CC9D67;
}

.tab-divider {
    width: 1px;
    height: 30px;
    background-color: #9E9E9E;
}

.statistic-table {
    border-spacing: 0;
    width: 100%;
}

.statistic-table thead.green {
    background: #297E60;
}

.statistic-table thead.blue {
    background: #3C639D;
}

.statistic-table thead.purple {
    background: #8166A4;
}

.statistic-table thead.yellow {
    background: #F5D147;
    color: #333333 !important;
}

.statistic-table thead.yellow tr th {
    color: #333333 !important;
}

.statistic-table thead tr th {
    font-family: "Noto Sans TC";
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.1em;
    text-align: center;
    color: white;
    padding: 16px;
    padding-left: 8px;
    padding-right: 8px;
}

.statistic-table tbody tr td {
    text-align: center;
}

.statistic-table tbody tr td {
    font-size: 15px;
    font-family: "Noto Sans TC";
    color: #333333;
    font-weight: 400;
}

.statistic-table tbody tr td.red {
    color: #EB1313;
}

.statistic-table tbody tr {
    font-size: 20px;
}

.statistic-table tbody tr.green.dark {
    background: #EAF6E2;
}

.statistic-table tbody tr.blue.dark {
    background: #E1F1F0;
}

.statistic-table tbody tr.purple.dark {
    background: #F0EBF4;
}

.statistic-table tbody tr.yellow.dark {
    background: #f6f0d9;
}

.statistic-table tbody tr td.title {
    height: 48px;
    font-size: 16px;
    color: white;
    letter-spacing: 2.4px;
    border-bottom: 1px solid white;
}

.statistic-table tbody tr td.title.green {
    background: #50A35C;
}

.statistic-table tbody tr td.title.blue {
    background: #3F7EAC;
}

.statistic-table tbody tr td.title.purple {
    background: #A789CD;
}

.statistic-table tbody tr td.title.yellow {
    background: #CAC7BB;
    color: #333333;
}

.statistics-table-wrapper {
    width: 100%;
    margin-bottom: 32px;
}

.statistics-table-wrapper .sidebar-buttons {
    justify-content: flex-start;
    gap: 16px;
    padding-left: 32px;
}

.statistics-table-wrapper .sidebar-buttons.green {
    color: #005A03;
}

.statistics-table-wrapper .sidebar-buttons.blue {
    color: #1F5194;
}

.statistics-table-wrapper .sidebar-buttons.purple {
    color: #5D4890;
}

.statistics-table-wrapper .sidebar-buttons.yellow {
    color: #333333;
}

.box .form-control {
    max-width: 300px;
    margin: 0 auto;
}

.datepicker {
    font-family: "Noto Sans TC";
}

.datepicker--cell.-range-to- {
    background: rgba(248, 206, 236, 0.4);
    border-color: rgba(248, 206, 236, 1);
}

.datepicker--cell.-selected-,
.datepicker--cell.-selected-.-focus- {
    background-color: #f8ceec;
    color: #000000;
}

.datepicker--cell.-in-range- {
    background: rgba(248, 206, 236, 0.2);
}

.datepicker--cell-day {
    font-weight: 500;
    color: #000000;
}

.form-control {
    height: 50px;
    font-size: 16px;
    color: #414D64;
    background: #ffffff;
    padding: 0 15px;
    border: 2px solid #DADEEA;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-align: center;
}

textarea.form-control {
    height: 120px;
    padding-top: 15px;
}

.form-control-plaintext {
    font-size: 18px;
    color: #434343;
}

button.form-control {
    text-overflow: ellipsis;
    overflow: hidden;
}

.form-control:focus {
    border-color: #37B448;
    -webkit-box-shadow: 0px 2px 6px rgba(55, 180, 72, .15);
    -moz-box-shadow: 0px 2px 6px rgba(55, 180, 72, .15);
    box-shadow: 0px 2px 6px rgba(55, 180, 72, .15);
}

.date-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
}

.statistics-more-content {
    font-family: "Noto Sans TC";
    font-size: 17px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.4em;
    cursor: pointer;
}

.statistics-more-content:hover {
    opacity: 0.8;
}

.statistics-more-content.green {
    color: #0C7510;
}

.statistics-more-content.blue {
    color: #333333;
}

.statistics-more-content.purple {
    color: #333333;
}

.statistics-more-content.yellow {
    color: #333333;
}

.statistic-paper-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 64px;
    display: flex;
    width: 100%;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
    background: white;
    max-height: calc(100vh - 64px);
}

.statistic-paper-wrapper.show {
    height: calc(100vh - 64px);
    overflow-y: auto;
    opacity: 1;
    pointer-events: auto;
    max-height: unset;
}

.statistic-paper {
    max-width: 1280px;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
}

.statistic-top-bar {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.statistic-top-bar .close-button {
    cursor: pointer;
    width: 32px;
    height: 32px;
}

.statistic-top-bar .close-button:hover {
    opacity: 0.8;
}

.statistic-paper-title {
    font-family: "Noto Sans TC";
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.15em;
    text-align: left;
}

.statistic-paper-title.green {
    color: #0C7510;
}

.statistic-paper-title.blue {
    color: #333333;
}

.statistic-paper-title.purple {
    color: #333333;
}

.statistic-paper-title.yellow {
    color: #333333;
}

.statistic-paper-details-table {
    width: 100%;
    margin-top: 16px;
    border-spacing: 0;
}

.statistic-paper-details-table * th {
    font-family: "Noto Sans TC";
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08em;
    text-align: center;
}

.statistic-paper-details-table * th.title {
    color: white;
    width: 128px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 3px;
    text-indent: 3px;
}

.statistic-paper-details-table * th.title.green {
    background: #50A35C;
}

.statistic-paper-details-table * th.title.blue {
    background: #3F7EAC;
}

.statistic-paper-details-table * th.title.purple {
    background: #8166A4;
}

.statistic-paper-details-table * th.title.yellow {
    background: #CAC7BB;
    color: #333333;
}

.statistic-paper-details-table tbody {
    border-top: 1px solid white;
}

.statistic-paper-details-table * th {
    background: #F4F4F4;
    border-bottom: 1px solid white;
}

.statistic-paper-details-table * th.index.green {
    background: #EAF6E2;
}

.statistic-paper-details-table * th.index.blue {
    background: #E1F1F0;
}

.statistic-paper-details-table * th.index.purple {
    background: #F0EBF4;
}

.statistic-paper-details-table * th.index.yellow {
    background: #F5F4F0;
}

.statistic-paper-details-table * th.red {
    color: #EB1313 !important;
}

.statistic-paper-details-questions {
    margin-top: 32px;
    border: 2.5px solid #95D346;
    border-radius: 4px;
}