.loading-screen-parent{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #000000D0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
    z-index: 9998;
}

.loading-screen-label{
    color: white;
    font-weight: 700;
    font-size: 24px;
}