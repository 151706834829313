@import "../variables.scss";

.modify-question-parent {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 2em;
    background-color: #EBF1FA;
    min-height: calc(100vh - $topbarHeight);
}

.modify-question-title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modify-question-title-group {
    display: flex;
    align-items: center;
}

.modify-question-title {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.15em;
    color: #226074;
}

.modify-question-difficulty {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #226074B0;
}

.abs-start {
    position: absolute;
    left: 0;
    top: 0;
}

.abs-end {
    position: absolute;
    right: 0;
    top: 0;
}

.modify-question-publish-group {
    display: flex;
    gap: 0.5em;
    align-items: center;
}

.modify-question-publish-text {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.3em;
    text-indent: 0.3em;
    color: #1781BC;
}

.modify-question-edit-paper-details-container {
    background-color: #000000D0;
    height: calc(100vh - $topbarHeight);
    position: absolute;
    top: 0;
    left: 0;
    margin-top: $topbarHeight;
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modify-question-edit-paper-details-dialog-container {
    background: #4A8FCF;
    border: 3px solid #4A8FCF;
    box-shadow: 0px -3px 45px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.modify-question-edit-paper-details-dialog-title-bar {
    display: flex;
    justify-content: space-between;
    height: 72px;
    min-width: 384px;
    align-items: center;
    padding: 0em 1em 0em 2em;
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.15em;
}

.modify-question-edit-paper-details-dialog-title-bar>img {
    margin-bottom: 1em;
}

.modify-question-edit-paper-details-dialog-content-container {
    background-color: white;
    padding: 2em;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-direction: column;
}

.modify-question-edit-paper-details-buttons-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    gap: 2em
}

.modify-question-edit-question-details-container {
    width: 100%;
    max-width: 720px;
    max-height: 90%;
    padding: 1.5em;
    background: white;
    border-radius: 4px;
    // display: flex;
    // flex-direction: column;
    align-items: center;
    overflow: auto;
    gap: 1em;
    box-shadow: 8px 8px 16px #00000020;
    display: grid;
    grid-template-rows: 36px 1fr 40px;
}

.height-100 {
    height: 100%;
}

.scroll {
    overflow: scroll;
    overflow-x: hidden;
}